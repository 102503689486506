import React from 'react'
import PropTypes from 'prop-types'
import { useMeasure } from 'react-use'
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button'
import '@reach/menu-button/styles.css'

import ArrowDown from '../../images/home/timeline-select-arrow.svg'

import { useTimeline } from '../../hooks'

import styles from './TimelineYearSelect.module.css'

function TimelineYearSelect({ activeYear, onYearSelect }) {
  const { contentYears } = useTimeline()
  const years = Object.keys(contentYears)
  const [ref, { width }] = useMeasure()

  function renderYears() {
    return years.map(year => (
      <MenuItem
        key={year}
        className={styles.item}
        onSelect={() => onYearSelect(year)}
      >
        {year}
      </MenuItem>
    ))
  }

  return (
    <div
      ref={ref}
      className={styles.container}
    >
      <Menu>
        <MenuButton className={styles.toggle}>
          {activeYear}

          <ArrowDown
            role='presentation'
            className={styles.icon}
          />
        </MenuButton>

        <MenuList
          className={styles.list}
          style={{ width }}
        >
          {renderYears()}
        </MenuList>
      </Menu>
    </div>
  )
}

TimelineYearSelect.propTypes = {
  activeYear: PropTypes.string.isRequired,
  onYearSelect: PropTypes.func.isRequired
}

export { TimelineYearSelect }
