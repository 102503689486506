import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import { useTimeline, useYearColorTransform } from '../../hooks'

import styles from './TimelineYear.module.css'

function TimelineYear({ year, index }) {
  const { isContentCollapsed, activeIndex, yearWidth } = useTimeline()
  const isTextVisible = !isContentCollapsed
  const color = useYearColorTransform(index)

  function getX() {
    if (activeIndex === index) {
      return 0
    } else if (activeIndex < index) {
      return '30%'
    }

    return '-30%'
  }

  return (
    <motion.li
      className={styles.year}
      style={{ width: yearWidth, color }}
      animate={{
        opacity: isTextVisible ? 1 : 0,
        x: isTextVisible ? 0 : getX()
      }}
      transition={{ delay: isTextVisible ? 0.2 : 0 }}
    >
      {year}
    </motion.li>
  )
}

TimelineYear.propTypes = {
  index: PropTypes.number,
  year: PropTypes.string
}

export { TimelineYear }
