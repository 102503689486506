// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-acesso-agua-js": () => import("./../../../src/pages/acesso-agua.js" /* webpackChunkName: "component---src-pages-acesso-agua-js" */),
  "component---src-pages-agua-crystal-js": () => import("./../../../src/pages/agua-crystal.js" /* webpackChunkName: "component---src-pages-agua-crystal-js" */),
  "component---src-pages-crystal-sabores-js": () => import("./../../../src/pages/crystal-sabores.js" /* webpackChunkName: "component---src-pages-crystal-sabores-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nova-crystal-js": () => import("./../../../src/pages/nova-crystal.js" /* webpackChunkName: "component---src-pages-nova-crystal-js" */)
}

