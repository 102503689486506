import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Button } from '../UI'

import styles from './SeeAlso.module.css'
import { Image } from './Image'

function SeeAlso({ title, description, to, linkText, image }) {
  const titleClassName = classnames('titleLarge', styles.title)
  const containerClassName = classnames('container', styles.container)
  const buttonMobileClassName = classnames(styles.button, styles.buttonMobile)

  return (
    <section className={containerClassName}>
      <div className={styles.text}>
        <h2 className={titleClassName}>{title}</h2>

        <p className={styles.description}>{description}</p>

        <Button
          to={to}
          isMuted
          className={styles.button}
        >
          {linkText}
        </Button>
      </div>

      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <Image
            fluid={image}
            alt={linkText}
          />
        </div>

        <Button
          to={to}
          isMuted
          className={buttonMobileClassName}
        >
          {linkText}
        </Button>
      </div>
    </section>
  )
}

SeeAlso.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  linkText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
}

export { SeeAlso }
