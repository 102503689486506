import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const useImageAgua = () => {
  const { water, waterIcon, sparklingWater, sparklingWaterIcon } = useStaticQuery(
    graphql`
      query CrystalAgua {
        waterIcon: file(relativePath: { eq: "agua-crystal/icons/water-drop.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sparklingWaterIcon: file(relativePath: { eq: "agua-crystal/icons/bubbles.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        water: file(relativePath: { eq: "agua-crystal/agua-sem-gas-crystal.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sparklingWater: file(relativePath: { eq: "agua-crystal/agua-com-gas-crystal.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return [
    {
      accordionTitle: 'Crystal com Gás',
      icon: sparklingWaterIcon.childImageSharp.fluid,
      image: sparklingWater.childImageSharp.fluid,
      color: 'black',
      title: (
        <>
          Água Crystal
          <br />
          Com Gás
        </>
      ),
      description: 'Gaseificada enquanto vai para a garrafa, a refrescância já começa ao ouvir o "tsssss".',
    },
    {
      accordionTitle: 'Crystal sem Gás',
      icon: waterIcon.childImageSharp.fluid,
      image: water.childImageSharp.fluid,
      color: 'blue',
      title: (
        <>
          Água Crystal
          <br />
          Sem Gás
        </>
      ),
      description: `Hidratação com simplicidade. Sem adicionar, retirar ou alterar qualquer
      característica original da fonte.`,
    }
  ]
}

export { useImageAgua }
