import React from 'react'
import { motion } from 'framer-motion'

import { useTimeline } from '../../hooks'

import Arrow from '../../images/home/timeline-arrow.svg'

import styles from './TimelineArrows.module.css'

const variants = {
  visible: {
    opacity: 1,
    visibility: 'visible'
  },

  hidden: {
    opacity: 0,
    transitionEnd: { visibility: 'hidden' }
  }
}

function TimelineArrows() {
  const { itemWidth, activeIndex, goToItem, content } = useTimeline()
  const prevButtonVariant = activeIndex === 0 ? 'hidden' : 'visible'
  const nextButtonVariant = activeIndex === content.length - 1 ? 'hidden' : 'visible'

  function onButtonClick(isPrev) {
    const index = Math.min(content.length - 1, Math.max(0, isPrev ? activeIndex - 1 : activeIndex + 1))
    goToItem(index)
  }

  function renderIcon(isPrev) {
    const iconClassName = isPrev ? styles.iconPrev : styles.iconNext

    return <Arrow className={iconClassName} />
  }

  return (
    <div
      style={{ width: itemWidth + 40 }}
      className={styles.container}
    >
      <motion.button
        title='Slide anterior'
        className={styles.button}
        variants={variants}
        animate={prevButtonVariant}
        onClick={() => onButtonClick(true)}
      >
        {renderIcon(true)}
      </motion.button>

      <motion.button
        title='Próximo slide'
        className={styles.button}
        variants={variants}
        animate={nextButtonVariant}
        onClick={() => onButtonClick(false)}
      >
        {renderIcon(false)}
      </motion.button>
    </div>
  )
}

export { TimelineArrows }
