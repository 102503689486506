import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import s from './Spinner.module.css'

const Spinner = React.memo(function Spinner(props) {
  const className = cn(s.spinner, props.className)
  const containerClassName = cn(s.wrapper, props.containerClassName)

  return (
    <div className={containerClassName}>
      <div className={className} />
    </div>
  )
})

Spinner.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string
}

export { Spinner }
