import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { motion } from 'framer-motion'

import styles from './Accordion.module.css'

function Accordion({ items, className, itemIdPrefix }) {
  const [activeIndexes, setActiveIndexes] = useState([0])
  const containerClassName = classnames(className, styles.accordion)

  function renderItems() {
    return items.map((item, index) => {
      const isActive = activeIndexes.includes(index)
      const contentId = `${itemIdPrefix}${index}`
      const iconClassName = classnames(styles.icon, {
        [styles.iconActive]: isActive
      })

      return (
        <div
          key={index}
          className={styles.item}
        >
          <dt className={styles.title}>
            <button
              className={styles.toggle}
              aria-controls={contentId}
              onClick={() => {
                if (isActive) {
                  setActiveIndexes(activeIndexes.filter(currentIndex => currentIndex !== index))
                } else {
                  setActiveIndexes([...activeIndexes, index])
                }
              }}
            >
              <span
                aria-hidden
                className={iconClassName}
              />
              {item.title}
            </button>
          </dt>

          <motion.dd
            id={contentId}
            className={styles.text}
            aria-hidden={!isActive}
            tabIndex={isActive ? 0 : -1}
            animate={{
              height: isActive ? 'auto' : 0
            }}
          >
            {item.text}
          </motion.dd>
        </div>
      )
    })
  }

  return <dl className={containerClassName}>{renderItems()}</dl>
}

Accordion.propTypes = {
  className: PropTypes.string,
  itemIdPrefix: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
      title: PropTypes.string
    })
  )
}

export { Accordion }
