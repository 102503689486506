import React, { useRef } from 'react'
import { motion } from 'framer-motion'
import FocusTrap from 'focus-trap-react'
import ScrollLock from 'react-scrolllock'
import { useClickAway } from 'react-use'
import PropTypes from 'prop-types'
import Bowser from 'bowser'

import styles from './Menu.module.css'
import { MenuToggle } from './MenuToggle'
import { MenuItems } from './MenuItems'

const Menu = ({ isOpen, toggleOpen, isWhite }) => {
  const ref = useRef(null)

  const browser = Bowser.getParser(navigator.userAgent)
  const useClipPathAnimate = browser.satisfies({
    firefox: '>=60',
    chrome: '>=66'
  })

  let openAnimate = {
    opacity: 1,
    visibility: 'visible',
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  }
  let closeAnimate = {
    opacity: 0,
    visibility: 'hidden',
    transition: {
      y: { stiffness: 1000 }
    }
  }

  if (useClipPathAnimate) {
    openAnimate = {
      clipPath: 'circle(200% at 40px 40px)',
      transition: {
        ease: 'easeInOut',
        duration: 0.5
      }
    }

    closeAnimate = {
      clipPath: 'circle(0px at 50px 46px)',
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 30,
        velocity: -1000
      }
    }
  }

  const sidebar = {
    open: openAnimate,
    closed: closeAnimate
  }

  useClickAway(ref, () => {
    if (!isOpen) {
      return
    }

    toggleOpen(false)
  })

  return (
    <FocusTrap active={isOpen}>
      <div>
        <motion.nav
          ref={ref}
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          className={styles.menu}
        >
          <ScrollLock isActive={isOpen} />
          <motion.div
            className={styles.background}
            variants={sidebar}
          >
            <MenuItems
              isOpen={isOpen}
              toggle={() => toggleOpen(!isOpen)}
            />
          </motion.div>
          <MenuToggle
            isOpen={isOpen}
            isWhite={isWhite}
            toggle={() => toggleOpen(!isOpen)}
          />
        </motion.nav>
      </div>
    </FocusTrap>
  )
}

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isWhite: PropTypes.bool,
  toggleOpen: PropTypes.func.isRequired
}

export { Menu }
