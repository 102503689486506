import React from 'react'
import noop from 'lodash/noop'

export const TIMELINE_INITIAL_STATE = {
  // Animation hooks
  dragX: {},
  yearsX: {},
  controls: {},
  dragControls: {},

  // Size hooks
  middleX: 0,
  fullWidth: 0,
  itemWidth: 0,
  constraints: {},
  windowWidth: 0,
  scrollBarWidth: 0,

  // States
  thumbs: [],
  content: [],
  contentYears: [],
  isDragging: false,
  setIsDragging: noop,
  activeIndex: 0,
  setActiveIndex: noop,
  isContentCollapsed: false,
  setIsContentCollapsed: noop,
  yearScale: 0,
  yearWidth: 0,
  yearsStartX: 0,
  isMobile: false,

  // Methods
  goToItem: noop,
  getNumberBetweenConstraints: noop
}

/* eslint-disable max-len */
export const TIMELINE_CONTENT = {
  2009: [
    {
      thumb: 'agua-e-disponibilidade',
      title: (
        <>
          Água <br />+ Disponibilidade
        </>
      ),
      subtitle: (
        <>
          Devolvendo água
          <br />
          ao meio ambiente
        </>
      ),
      text: (
        <>
          Numa parceria incrível com a Fundação Amazonas Sustentável (FAS), devolvemos ao meio ambiente toda a água que
          usamos em nossas fábricas. No Brasil, a gente repõe 100% dessa água desde 2013.
        </>
      )
    }
  ],
  2011: [
    {
      thumb: 'materia-prima-vegetal',
      title: (
        <>
          Matéria-prima
          <br />
          Vegetal
        </>
      ),
      subtitle: <>Fazendo PET com cana-de-açúcar</>,
      text: (
        <>
          Com matéria-prima vinda da cana-de-açúcar, essa PET diminuiu em até 25% as emissões de CO² no meio ambiente.
          Mas ela começou a sair de linha a partir de 2017. O motivo? A gente entendeu que reaproveitar o plástico já
          existente no mundo é muito melhor do criar novos, não importando de onde ele vem.{' '}
        </>
      )
    }
  ],
  2013: [
    {
      thumb: 'plastico-e-sustentabilidade',
      title: <>Plástico + Sustentabilidade</>,
      subtitle: (
        <>
          Colocando menos plástico
          <br />
          na garrafa
        </>
      ),
      text: (
        <>
          A água de algumas das nossas fontes (Bauru/SP, Duque de Caxias/RJ, Maceió/AL e Mogi das Cruzes/SP) ganhou uma
          garrafa feita com menos plástico. De 15,6g para 12g, ela é mais flexível e fácil de ser torcida (seu tamanho
          diminui em até 37%). Isso também melhora as condições da coleta e o todo o processo de reciclagem.
        </>
      )
    }
  ],
  2017: [
    {
      thumb: 'agua-e-acesso',
      title: (
        <>
          Água <br />+ Acesso
        </>
      ),
      subtitle: (
        <>
          Levando água para
          <br />
          as comunidades de baixa
          <br />
          renda
        </>
      ),
      text: (
        <>
          Nos reunimos com outras organizações para levar água para áreas rurais e isoladas do Brasil.<br />
          Hoje, já estamos em 8 estados e contribuímos para ampliar o acesso à água segura para 135 mil pessoas em mais de 370 comunidades
        </>
      )
    }
  ],
  2018: [
    {
      thumb: 'catadores',
      title: (
        <>
          Apoio aos <br />
          Catadores
        </>
      ),
      subtitle: 'Fortalecendo associações e cooperativas em todo o Brasil',
      text: (
        <>
          Nós queremos que todo plástico seja descartado e reaproveitado de forma correta.
          Para que este desejo seja real, hoje já apoiamos mais de 220 Associações e Cooperativas em 132 cidades do país.
        </>
      )
    }
  ],
  2020: [
    {
      thumb: 'feita-de-outras-garrafas',
      title: (
        <>
          100% feita
          <br />
          de outras garrafas
        </>
      ),
      subtitle: (
        <>
          Reaproveitando
          <br />o plástico
        </>
      ),
      text: (
        <>
          Agora, nossas garrafas são feitas só de plástico reciclado. Desse jeito, já paramos de colocar plástico novo
          no mercado e só reaproveitamos o que já existe.
        </>
      )
    },
    {
      thumb: 'refil',
      title: 'Refil',
      subtitle: (
        <>
          Hidratando os funcionários
          <br />
          sem envolver embalagem
        </>
      ),
      text: (
        <>
          Se tem uma coisa que não combina com o nosso escritório do Rio de Janeiro é entregar água na garrafa. Dentro
          da própria empresa, só entregamos água e ponto.
        </>
      )
    },
    {
      thumb: 'assinatura-circular',
      title: (
        <>
          Assinatura
          <br />
          Circular
        </>
      ),
      subtitle: (
        <>
          Recebendo um produto
          <br />
          e garantindo seu descarte
          <br />
          correto
        </>
      ),
      text: (
        <>
          Se tem uma coisa que não combina com o nosso escritório do Rio de Janeiro é entregar água na garrafa. Dentro
          da própria empresa, só entregamos água e ponto.
        </>
      )
    },
    {
      thumb: 'sustentapet',
      title: 'Sustentapet',
      subtitle: (
        <>
          Garantindo a sustentabilidade
          <br />
          da coleta
        </>
      ),
      text: (
        <>
          Em 2020 comemoramos 100 milhões de garrafas PET coletadas e recicladas no primeiro ano de operação da SustentaPET,
          um centro de coleta de resíduos pós-consumo criado em parceria com a Coca-Cola FEMSA para fomentar a economia circular
          da cadeia de reciclagem e contribuir para a geração de renda para cooperativas e catadores de materiais recicláveis.
        </>
      )
    }
  ]
}
