import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWindowSize } from 'react-use'
import Carousel from 'react-slick'

import { VerticalAccordionContent, VerticalAccordionItem } from '../VerticalAccordion'

import styles from './VerticalAccordion.module.css'

function VerticalAccordion({ className, items }) {
  const initialWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const windowSize = useWindowSize(initialWidth)
  const [activeIndex, setActiveIndex] = useState(0)
  const accordionClassName = classnames(styles.accordion, className)
  const carouselClassName = classnames(styles.carousel, className)
  const dotsClassName = classnames('dots', styles.dots)
  const isMobile = windowSize.width <= 920

  function renderAccordionItems() {
    return items.map((item, index) => {
      const isActive = activeIndex === index

      return (
        <VerticalAccordionItem
          {...item}
          key={index}
          isActive={isActive}
          onToggleClick={() => setActiveIndex(index)}
        >
          <VerticalAccordionContent
            {...item}
            isActive={isActive}
          />
        </VerticalAccordionItem>
      )
    })
  }

  function renderCarouselItems() {
    return items.map((item, index) => (<VerticalAccordionContent
      isActive
      key={index}
      {...item}
    />))
  }

  if (!isMobile) {
    return <dl className={accordionClassName}>{renderAccordionItems()}</dl>
  }

  return (
    <Carousel
      dots
      variableWidth
      adaptiveHeight
      arrows={false}
      dotsClass={dotsClassName}
      className={carouselClassName}
    >
      {renderCarouselItems()}
    </Carousel>
  )
}

VerticalAccordion.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      accordionTitle: PropTypes.string.isRequired,
      color: PropTypes.oneOf(['black', 'blue', 'red', 'yellow', 'orange', 'green']),
      icon: PropTypes.object.isRequired
    })
  )
}

export { VerticalAccordion }
