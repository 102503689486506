import React from 'react'
import PropTypes from 'prop-types'

import { TimelineContent } from '../Timeline'

import { TimelineProvider } from '../../contexts/Timeline'

function Timeline({ thumbs, content }) {
  return (
    <TimelineProvider
      thumbs={thumbs}
      content={content}
    >
      <TimelineContent />
    </TimelineProvider>
  )
}

Timeline.propTypes = {
  content: PropTypes.object,
  thumbs: PropTypes.object
}

export { Timeline }
