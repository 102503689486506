import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const PRODUCT_LINK = 'https://www.paodeacucar.com/especial/cocacola_agua?qt=12&p=1&gt=list'

const useImageSabores = () => {
  const {
    redFruit,
    redFruitIcon,
    redFruitPet,
    redFruitLata,
    tangerineIcon,
    tangerine,
    tangerinePet,
    tangerineLata,
    mangoIcon,
    mango,
    mangoPet,
    mangoLata,
    lemonIcon,
    lemon,
    lemonPet,
    lemonLata
  } = useStaticQuery(
    graphql`
      query CrystalSabores {
        redFruitIcon: file(relativePath: { eq: "crystal-sabores/icons/red-fruit.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        redFruit: file(relativePath: { eq: "crystal-sabores/frutas-vermelhas-crystal.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        redFruitPet: file(relativePath: { eq: "crystal-sabores/icons/frutas-vermelhas-pet.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        redFruitLata: file(relativePath: { eq: "crystal-sabores/icons/frutas-vermelhas-lata.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tangerineIcon: file(relativePath: { eq: "crystal-sabores/icons/tangerina-capim.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tangerine: file(relativePath: { eq: "crystal-sabores/tangerina-capim-crystal.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tangerinePet: file(relativePath: { eq: "crystal-sabores/icons/tangerina-capim-pet.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tangerineLata: file(relativePath: { eq: "crystal-sabores/icons/tangerina-capim-lata.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mangoIcon: file(relativePath: { eq: "crystal-sabores/icons/manga.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mango: file(relativePath: { eq: "crystal-sabores/manga-crystal.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mangoPet: file(relativePath: { eq: "crystal-sabores/icons/manga-pet.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mangoLata: file(relativePath: { eq: "crystal-sabores/icons/manga-lata.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        lemonIcon: file(relativePath: { eq: "crystal-sabores/icons/limao.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        lemon: file(relativePath: { eq: "crystal-sabores/limao-crystal.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        lemonPet: file(relativePath: { eq: "crystal-sabores/icons/limao-pet.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        lemonLata: file(relativePath: { eq: "crystal-sabores/icons/limao-lata.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return [
    {
      accordionTitle: 'Saborizada Frutas Vermelhas',
      icon: redFruitIcon.childImageSharp.fluid,
      image: redFruit.childImageSharp.fluid,
      color: 'red',
      title: (
        <>
          Crystal Saborizada
          <br />
          Frutas Vermelhas
        </>
      ),
      description: `Sabor adocicado muito popular entre as sobremesas. E você pode experimentar o
        aroma das frutas vermelhas de um novo jeito.`,
      pack: {
        lata: {
          alt: 'Lata Frutas Vermelhas',
          image: redFruitLata.childImageSharp.fluid,
          description: 'Lata'
        },
        pet: {
          alt: 'Pet 500ml Frutas Vermelhas',
          image: redFruitPet.childImageSharp.fluid,
          description: '500 ml'
        }
      },
      link: PRODUCT_LINK
    },
    {
      accordionTitle: 'Saborizada Tangerina e Capim Limão',
      icon: tangerineIcon.childImageSharp.fluid,
      image: tangerine.childImageSharp.fluid,
      color: 'orange',
      title: (
        <>
          Crystal Saborizada
          <br />
          Tangerina de Capim Limão
        </>
      ),
      description: `Leve e inusitado. Enquanto a tangerina dá aquele toque cítrico,
        o capim limão empresta um aroma inconfundível ao mix.`,
      pack: {
        lata: {
          alt: 'Lata Tangerina e Capim Limão',
          image: tangerineLata.childImageSharp.fluid,
          description: 'Lata'
        },
        pet: {
          alt: 'Pet 500ml Tangerina e Capim Limão',
          image: tangerinePet.childImageSharp.fluid,
          description: '500 ml'
        }
      },
      link: PRODUCT_LINK
    },
    {
      accordionTitle: 'Saborizada Manga',
      icon: mangoIcon.childImageSharp.fluid,
      image: mango.childImageSharp.fluid,
      color: 'yellow',
      title: (
        <>
          Crystal Saborizada
          <br />
          Manga
        </>
      ),
      description: `Perfeita para quem é fã do gostinho marcante da manga. Além de saudável,
        a fruta é muito saborosa e super tropical.`,
      pack: {
        lata: {
          alt: 'Lata Manga',
          image: mangoLata.childImageSharp.fluid,
          description: 'Lata'
        },
        pet: {
          alt: 'Pet 500ml Manga',
          image: mangoPet.childImageSharp.fluid,
          description: '500 ml'
        }
      },
      link: PRODUCT_LINK
    },
    {
      accordionTitle: 'Saborizada Limão',
      icon: lemonIcon.childImageSharp.fluid,
      image: lemon.childImageSharp.fluid,
      color: 'green',
      title: (
        <>
          Crystal Saborizada
          <br />
          Limão
        </>
      ),
      description: `Feitos um para o outro. A tradicional combinação de água com gás e
        limão em sua versão mais sublime e refrescante.`,
      pack: {
        lata: {
          alt: 'Lata Limão',
          image: lemonLata.childImageSharp.fluid,
          description: 'Lata'
        },
        pet: {
          alt: 'Pet 500ml Limão',
          image: lemonPet.childImageSharp.fluid,
          description: '500 ml'
        }
      },
      link: PRODUCT_LINK
    }
  ]
}

export { useImageSabores }
