import React from 'react'

// Vendor
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// Global
import './src/styles/fonts.css'
import './src/styles/reset.css'
import './src/styles/global.css'
import './src/styles/dots.css'

import { Layout } from './src/components/UI'

// eslint-disable-next-line react/prop-types
export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>
