import React from 'react'
import classnames from 'classnames'
import { useStaticQuery, graphql } from 'gatsby'

import { Image } from '../UI'

import styles from './CrystalForEveryThrist.module.css'

function CrystalForEveryThrist() {
  const containerClassName = classnames('container', styles.container)
  const titleClassName = classnames('title', styles.title)

  const { image } = useStaticQuery(
    graphql`
      query CrystalForEveryThristQuery {
        image: file(relativePath: { eq: "agua-crystal/familia-crystal.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <section className={containerClassName}>
      <h2 className={titleClassName}>Crystal para todas as sedes</h2>

      <Image
        alt='Família Crystal'
        fluid={image.childImageSharp.fluid}
        className={styles.imageWrapper}
      />
    </section>
  )
}

export { CrystalForEveryThrist }
