import React from 'react'
import classnames from 'classnames'

import { Accordion } from '../UI'
import { useAccordionCities } from '../../hooks'

import styles from './CrystalWaterSources.module.css'

function CrystalWaterSources() {
  const titleClassName = classnames('titleLarge', styles.title)
  const accordionCities = useAccordionCities()

  return (
    <section className='container'>
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <h2 className={titleClassName}>de onde vem nossa água?</h2>

          <p className={styles.text}>
            A água Crystal é extraída de diversas fontes minerais espalhadas pelo Brasil todo. Nós temos a concessão
            delas e uma única missão: levar essa água até você.
          </p>

          <p className={styles.text}>
            Para descobrir de onde vem uma água específica, é bem simples, basta conferir no rótulo.
          </p>
        </div>

        <Accordion
          className={styles.accordionWrapper}
          itemIdPrefix='crystalWaterAccordionItem'
          items={accordionCities}
        />
      </div>
    </section>
  )
}

export { CrystalWaterSources }
