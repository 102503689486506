import React from 'react'
import { motion } from 'framer-motion'

import { TimelineYear } from '.'

import { useTimeline } from '../../hooks'

import styles from './TimelineYears.module.css'

function TimelineYears() {
  const { content, fullWidth, yearsStartX, yearsX } = useTimeline()

  return (
    <div className={styles.wrapper}>
      <motion.ol
        initial={{ x: yearsStartX }}
        className={styles.container}
        style={{ x: yearsX, width: fullWidth }}
      >
        {content.map(({ year }, index) => (
          <TimelineYear
            key={index}
            index={index}
            year={year}
          />
        ))}
      </motion.ol>
    </div>
  )
}

export { TimelineYears }
