import { useEffect } from 'react'
import { useMotionValue } from 'framer-motion'

export function useYearsXTransform({ dragX, middleX, yearScale, yearsStartX }) {
  const yearsX = useMotionValue(yearsStartX)

  useEffect(() => {
    const unsubscribe = dragX.onChange(() => {
      const value = ((dragX.get() - middleX) * yearScale) + yearsStartX
      yearsX.set(value)
    })
    return () => unsubscribe()
  }, [dragX, middleX, yearScale, yearsStartX, yearsX])

  return yearsX
}
