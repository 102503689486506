import React from 'react'
import PropTypes from 'prop-types'

import { useStaticQuery, graphql } from 'gatsby'

import styles from './CrystalWaterPh.module.css'

function CrystalWaterPh(props) {
  const { image } = useStaticQuery(
    graphql`
      query CrystalWaterPhQuery {
        image: file(relativePath: { eq: "agua-crystal/water-drop.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <div className={styles.container}>
      <img
        alt=''
        src={image.publicURL}
        className={styles.icon}
      />

      <div className={styles.text}>{props.children}</div>
    </div>
  )
}

CrystalWaterPh.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired
}

export { CrystalWaterPh }
