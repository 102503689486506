import React from 'react'
import PropTypes from 'prop-types'

import { VerticalAccordion } from '../UI'

import styles from './CrystalVerticalAccordion.module.css'

function CrystalVerticalAccordion(props) {
  return (<VerticalAccordion
    className={styles.verticalAccordion}
    items={props.items}
  />)
}

CrystalVerticalAccordion.propTypes = {
  items: PropTypes.array.isRequired
}

export { CrystalVerticalAccordion }
