import React, { useState, useMemo } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { SEO } from './'
import LogoHeader from '../../images/logo.svg'
import { Menu } from '../Menu/Menu'
import { NEW_CRYSTAL } from '../../constants/routes'

import styles from './Header.module.css'

const Header = () => {
  const checkURL = [NEW_CRYSTAL]
  const pathname = window.location.pathname
  const [isOpen, toggleOpen] = useState(false)
  const isWhite = useMemo(() => !!RegExp(checkURL.join('|')).exec(pathname), [checkURL, pathname])
  const linkClassName = cn(styles.linkLogo, {
    [styles.linkOpen]: isOpen,
    [styles.logoWhite]: isWhite
  })

  const data = useStaticQuery(graphql`
    query HeaderTitleQuery {
      site {
        siteMetadata {
          description
        }
      }
    }
  `)

  return (
    <header className={styles.header}>
      <SEO description={data.site.siteMetadata.description} />
      <Link
        className={linkClassName}
        aria-label='Home'
        to='/'
      >
        <LogoHeader className={styles.logo} />
      </Link>
      <Menu
        toggleOpen={toggleOpen}
        isOpen={isOpen}
        isWhite={isWhite}
      />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export { Header }
