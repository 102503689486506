import { transform, useMotionValue } from 'framer-motion'
import { useEffect, useMemo } from 'react'

import { useTimeline } from '.'

export function useYearColorTransform(index) {
  const { yearsX, dragX, activeIndex, yearWidth, yearsStartX } = useTimeline()
  const yearMiddleX = yearsStartX - (yearWidth * index)
  const yearStartX = yearMiddleX - yearWidth
  const yearEndX = yearMiddleX + yearWidth
  const activeColor = 'rgba(0, 102, 204, 1)'
  const inactiveColor = 'rgba(185, 185, 185, .16)'
  const initialColor = activeIndex === index ? activeColor : inactiveColor
  const color = useMotionValue(initialColor)
  const transformColor = useMemo(
    () => transform([yearStartX, yearMiddleX, yearEndX], [inactiveColor, activeColor, inactiveColor]),
    [yearEndX, yearMiddleX, yearStartX]
  )

  useEffect(() => {
    const unsubscribe = dragX.onChange(() => {
      color.set(transformColor(yearsX.get()))
    })

    return () => unsubscribe()
  }, [color, dragX, yearsX, transformColor])

  return color
}
