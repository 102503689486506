import React from 'react'
import classnames from 'classnames'
import { motion } from 'framer-motion'

import { useTimeline } from '../../hooks'

import { TimelineYears, TimelineSliderItem, TimelineArrows } from '.'

import styles from './TimelineSlider.module.css'

function TimelineSlider() {
  const {
    controls,
    dragControls,
    dragX,
    isDragging,
    setIsDragging,
    setActiveIndex,
    isContentCollapsed,
    setIsContentCollapsed,
    middleX,
    itemWidth,
    constraints,
    getNumberBetweenConstraints,
    content,
    thumbs
  } = useTimeline()

  const contentClassName = classnames(styles.content, {
    [styles.contentIsDragging]: isDragging
  })

  function renderItems() {
    return content.map((item, index) => {
      const thumb = thumbs.allFile.edges.find(t => t.node.name === item.thumb).node

      return (<TimelineSliderItem
        {...item}
        key={index}
        index={index}
        thumb={thumb}
      />)
    })
  }

  return (
    <div
      className={contentClassName}
      onMouseDown={event => dragControls.start(event)}
    >
      <div className={styles.wrapper}>
        <TimelineArrows />

        <motion.ol
          drag='x'
          x={dragX}
          dragListener={false}
          animate={controls}
          initial={{ x: middleX }}
          dragControls={dragControls}
          dragMomentum={false}
          dragConstraints={constraints}
          onDragStart={() => {
            setIsDragging(true)

            if (isContentCollapsed) {
              setIsContentCollapsed(false)
            }
          }}
          onDragEnd={(event, info) => {
            const cappedVelocity =
              info.velocity.x < 0 ? Math.max(itemWidth / -2, info.velocity.x) : Math.min(itemWidth / 2, info.velocity.x)
            const targetX = info.point.x + cappedVelocity
            const cappedX = Math.round(targetX / itemWidth) * itemWidth
            const x = getNumberBetweenConstraints(cappedX + middleX)
            const newActiveIndex = Math.abs(Math.round(x / itemWidth))

            controls.start({ x }, { duration: 0.5 })
            setIsDragging(false)
            setActiveIndex(newActiveIndex)
          }}
          className={styles.list}
        >
          {renderItems()}
        </motion.ol>
      </div>

      <TimelineYears />
    </div>
  )
}

export { TimelineSlider }
