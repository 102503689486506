import React from 'react'
import Img from 'gatsby-image'

import styles from './Image.module.css'

function Image(props) {
  return (
    <Img
      {...props}
      placeholderClassName={styles.placeholder}
    />
  )
}

export { Image }
