import React, { useState } from 'react'
import classnames from 'classnames'
import { useMount } from 'react-use'

import { Spinner } from '../UI'

import { TimelineNav, TimelineSlider, TimelineMobile } from '.'

import { useTimeline } from '../../hooks'

import styles from './TimelineContent.module.css'

function TimelineContent() {
  const { isMobile } = useTimeline()
  const containerClassName = classnames('container', styles.container)
  const [isMounted, setIsMounted] = useState(true)

  useMount(() => setIsMounted(false))

  if (isMounted) {
    return (
      <section className={styles.spinnerWrapper}>
        <Spinner />
      </section>
    )
  }

  if (isMobile) {
    return (
      <section className={styles.containerMobile}>
        <TimelineMobile />
      </section>
    )
  }

  return (
    <section className={containerClassName}>
      <div className={styles.wrapper}>
        <TimelineNav />
        <TimelineSlider />
      </div>
    </section>
  )
}

TimelineContent.propTypes = {}

export { TimelineContent }
