import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useScrollbarWidth, useWindowSize } from 'react-use'
import { useAnimation, useDragControls, useMotionValue } from 'framer-motion'

import { TimelineContext } from '.'

import { useYearsXTransform } from '../../hooks'

function flattenContent(content) {
  return Object.entries(content).flatMap(([key, items]) => items.map(item => ({ year: key, ...item })))
}

function TimelineProvider({ thumbs, children, ...props }) {
  const scrollBarWidth = useScrollbarWidth()
  const controls = useAnimation()
  const dragControls = useDragControls()
  const dragX = useMotionValue(0)
  const initialWidth = typeof window !== 'undefined' ? window.innerWidth : 0
  const windowSize = useWindowSize(initialWidth)
  const [isDragging, setIsDragging] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isContentCollapsed, setIsContentCollapsed] = useState(false)
  const contentYears = props.content
  const content = useMemo(() => flattenContent(contentYears), [contentYears])
  const windowWidth = windowSize.width - scrollBarWidth
  const itemWidth = (windowWidth / 100) * 60.13
  const fullWidth = itemWidth * content.length
  const middleX = (windowWidth - itemWidth) / 2
  const constraints = { left: -((fullWidth - windowWidth) + middleX), right: middleX }
  const isMobile = windowWidth < 920
  const yearScale = 0.5
  const yearWidth = itemWidth * yearScale
  const yearsStartX = (windowWidth * yearScale) - (yearWidth * yearScale)
  const yearsX = useYearsXTransform({ yearsStartX, dragX, middleX, yearScale })

  useEffect(() => {
    dragX.updateAndNotify(middleX, true)
    setActiveIndex(0)
  }, [dragX, middleX])

  function goToItem(index) {
    const newX = index * -itemWidth
    const x = getNumberBetweenConstraints(newX + middleX)
    controls.start({ x }, { duration: 0.5 })
    setActiveIndex(index)

    if (isContentCollapsed) {
      setIsContentCollapsed(false)
    }
  }

  function getNumberBetweenConstraints(value) {
    return Math.min(constraints.right, Math.max(constraints.left, value))
  }

  const context = {
    // Animation hooks
    dragX,
    yearsX,
    controls,
    dragControls,

    // Size hooks
    middleX,
    fullWidth,
    itemWidth,
    constraints,
    windowWidth,
    scrollBarWidth,

    // States
    thumbs,
    content,
    contentYears,
    isDragging,
    setIsDragging,
    activeIndex,
    setActiveIndex,
    isContentCollapsed,
    setIsContentCollapsed,
    yearScale,
    yearWidth,
    yearsStartX,
    isMobile,

    // Methods
    goToItem,
    getNumberBetweenConstraints
  }

  return <TimelineContext.Provider value={context}>{children}</TimelineContext.Provider>
}

TimelineProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  content: PropTypes.object,
  thumbs: PropTypes.object
}

export { TimelineProvider }
