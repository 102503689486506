import React from 'react'
import classnames from 'classnames'

import { useTimeline } from '../../hooks'

import styles from './TimelineNav.module.css'

import DragIcon from '../../images/home/drag.svg'

function TimelineNav() {
  const { activeIndex, goToItem, content, contentYears } = useTimeline()

  function isActiveYear(year) {
    return content[activeIndex].year === year
  }

  function renderDots(items, initialIndex) {
    return items.map((item, index) => {
      const dotIndex = initialIndex + index
      const dotClassName = classnames(styles.dot, {
        [styles.dotIsActive]: activeIndex === dotIndex
      })

      return (
        <li
          key={item.thumb}
          className={styles.dotWrapper}
        >
          <button
            className={dotClassName}
            title={`Ir para a seção "${item.title}"`}
            onClick={() => goToItem(dotIndex)}
          />
        </li>
      )
    })
  }

  function renderTimes() {
    let totalIndex = 0

    return Object.entries(contentYears).map(([year, items]) => {
      const initialIndex = totalIndex
      const yearClassName = classnames(styles.year, {
        [styles.yearIsActive]: isActiveYear(year)
      })
      totalIndex += items.length

      return (
        <li
          key={year}
          className={styles.listItem}
        >
          <ul className={styles.dots}>{renderDots(items, initialIndex)}</ul>

          <button
            className={yearClassName}
            onClick={() => goToItem(initialIndex)}
          >
            {year}
          </button>
        </li>
      )
    })
  }

  return (
    <>
      <ol className={styles.list}>{renderTimes()}</ol>

      <p className={styles.info}>
        Arraste para ver o que estamos fazendo
        <DragIcon
          role='presentation'
          className={styles.icon}
        />
      </p>
    </>
  )
}

export { TimelineNav }
