import React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import {
  HOME_ROUTE,
  NEW_CRYSTAL,
  CRYSTAL_WATER,
  CRYSTAL_TASTES,
  ACCESS_WATER,
  ONLINE_SHOP
} from '../../constants/routes'

import styles from './MenuItems.module.css'

const variantsUl = {
  open: {
    visibility: 'visible',
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    visibility: 'hidden',
    transition: { staggerChildren: 0.05, staggerDirection: -1, delay: 0.5 }
  }
}

const variantsLi = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}

const Item = props => {
  const toggle = props.toggle || null

  return (
    <motion.li
      className={styles.item}
      variants={variantsLi}
      onClick={toggle}
    >
      {props.children}
    </motion.li>
  )
}

Item.propTypes = {
  children: PropTypes.object.isRequired,
  toggle: PropTypes.func
}

const MenuItems = ({ toggle }) => (
  <motion.ul
    className={styles.items}
    variants={variantsUl}
  >
    <Item toggle={toggle}>
      <Link to={HOME_ROUTE}>Home</Link>
    </Item>
    <Item toggle={toggle}>
      <Link to={NEW_CRYSTAL}>A nova crystal</Link>
    </Item>
    <Item toggle={toggle}>
      <Link to={CRYSTAL_WATER}>Água Crystal</Link>
    </Item>
    <Item toggle={toggle}>
      <Link to={CRYSTAL_TASTES}>Crystal Sabores</Link>
    </Item>
    <Item toggle={toggle}>
      <Link to={ACCESS_WATER}>Acesso à água</Link>
    </Item>
    <Item toggle={toggle}>
      <a
        href={ONLINE_SHOP}
        target='_blank'
        rel='noopener noreferrer'
      >
        Compre online
      </a>
    </Item>
  </motion.ul>
)

MenuItems.propTypes = {
  toggle: PropTypes.func.isRequired
}

export { MenuItems }
