import React, { Fragment } from 'react'

import { CrystalWaterPh } from '../components/Crystal'

const useAccordionCities = () => {
  const cities = [
    {
      title: 'Anápolis (GO)',
      text: (
        <>
          <strong>Mina da Lua</strong>
          <br />
          Av. Sérvio T Jayme, 1600 - Zona Rural, Anáp km olis - GO,
          <br />
          75000-000
          <br />
          <CrystalWaterPh>PH a 25º C: 6,41</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Bauru (SP)',
      text: (
        <>
          <strong>José Gregório</strong>
          <br />
          Via de Acesso Eng. Horacio Frederico Pyles, Bauru - SP
          <br />
          <CrystalWaterPh>PH a 25º C: 8,56</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Bauru (SP)',
      text: (
        <>
          <strong>São Bento</strong>
          <br />
          Via de Acesso Eng. Horacio Frederico Pyles, Bauru - SP
          <br />
          <CrystalWaterPh>PH a 25º C: 8,57</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Dias D´Ávila (BA)',
      text: (
        <>
          <strong>Nossa Senhora Aparecida</strong>
          <br />
          Rodovia BA, 093 - S/N - KM 18, Dias D`avila, Bahia,
          <br />
          42850-000
          <br />
          <CrystalWaterPh>PH a 25º C: 5,35</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Duque de Caxias (RJ)',
      text: (
        <>
          <strong>Andina I</strong>
          <br />
          Av. Automóvel Clube, 4945 - Taquara, Duque de Caxias - RJ,
          <br />
          25272-030
          <br />
          <CrystalWaterPh>PH a 25º C: 5,82</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Duque de Caxias (RJ)',
      text: (
        <>
          <strong>Andina II</strong>
          <br />
          Av. Automóvel Clube, 4945 - Taquara, Duque de Caxias - RJ,
          <br />
          25272-030
          <br />
          <CrystalWaterPh>PH a 25º C: 5,97</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Luziânia (GO)',
      text: (
        <>
          <strong>Gênesis 1</strong>
          <br />
          GO - 404 Km 11 Área rural, Luziânia - GO,
          <br />
          72801-150
          <br />
          <CrystalWaterPh>PH a 25º C: 7,23</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Luziânia (GO)',
      text: (
        <>
          <strong>Gênesis 2</strong>
          <br />
          GO - 404 Km 11 Área rural, Luziânia - GO,
          <br />
          72801-150
          <br />
          <CrystalWaterPh>PH a 25º C: 7,33</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Maceió (AL)',
      text: (
        <>
          <strong>Santa Maria</strong>
          <br />
          1740, Via Secundária 2, 1552 - Tabuleiro do Martins, Maceió-AL,
          <br />
          57081-016
          <br />
          <CrystalWaterPh>PH a 25º C: 5,63</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Maceió (AL)',
      text: (
        <>
          <strong>São Bento</strong>
          <br />
          1740, Via Secundária 2, 1552 - Tabuleiro do Martins, Maceió-AL,
          <br />
          57081-016
          <br />
          <CrystalWaterPh>PH a 25º C: 5,3</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Mogi das Cruzes (SP)',
      text: (
        <>
          <strong>Ycuara</strong>
          <br />
          Av. Francisco Ferreira Lopes, 4303 - Vila Jundiai, Mogi das Cruzes - SP,
          <br />
          08745-000
          <br />
          <CrystalWaterPh>PH a 25º C: 7,32</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Mogi das Cruzes (SP)',
      text: (
        <>
          <strong>Ycuara</strong>
          <br />
          Av. Francisco Ferreira Lopes, 4303 - Vila Jundiai, Mogi das Cruzes - SP,
          <br />
          08745-000
          <br />
          <CrystalWaterPh>PH a 25º C: 7,36</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Potirendaba (SP)',
      text: (
        <>
          <strong>Fonte das Flores</strong>
          <br />
          Rodovia Abel Pinho Maia, Km 12,5 s/n - Zona Rural, Potirendaba - SP,
          <br />
          15105-000
          <br />
          <CrystalWaterPh>PH a 25º C: 9,43</CrystalWaterPh>
        </>
      )
    },
    {
      title: 'Ribeirão Preto (SP)',
      text: (
        <>
          <strong>Ipiranga</strong>
          <br />
          Av. Dom Pedro I, 2270 - Ipiranga, Ribeirão Preto - SP,
          <br />
          14085-580
          <br />
          <CrystalWaterPh>PH a 25º C: 6,1</CrystalWaterPh>
        </>
      )
    }
  ]

  return cities.reduce((cityAcc, city, index) => {
    const cityIndex = cityAcc.findIndex(c => c.title === city.title)
    const text = <Fragment key={index}>{city.text}</Fragment>

    if (cityIndex > -1) {
      cityAcc[cityIndex].text.push(text)

      return cityAcc
    }

    return [
      ...cityAcc,
      {
        title: city.title,
        text: [text]
      }
    ]
  }, [])
}

export { useAccordionCities }
