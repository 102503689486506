import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-slick'
import classnames from 'classnames'

import styles from './NumberCarousel.module.css'

function NumberCarousel({ items, className }) {
  const carouselClassName = classnames(styles.carousel, className)
  const dotsClassName = classnames('dots', styles.dots)

  function renderItems() {
    return items.map((item, index) => (
      <div
        key={index}
        className={styles.item}
      >
        <span
          aria-hidden
          className={styles.number}
        >
          {index + 1}
        </span>

        <div className={styles.textWrapper}>
          <h3 className={styles.title}>{item.title}</h3>
          <p className={styles.text}>{item.text}</p>
        </div>
      </div>
    ))
  }

  return (
    <Carousel
      dots
      adaptiveHeight
      arrows={false}
      slidesPerRow={2}
      className={carouselClassName}
      dotsClass={dotsClassName}
      responsive={[
        {
          breakpoint: 768,
          settings: {
            slidesPerRow: 1
          }
        }
      ]}
    >
      {renderItems()}
    </Carousel>
  )
}

NumberCarousel.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      title: PropTypes.string
    })
  )
}

export { NumberCarousel }
