import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classnames from 'classnames'

import styles from './Button.module.css'

function Button({ children, className, to, href, isMuted = false, ...otherProps }) {
  const containerClassName = classnames(styles.button, className, {
    [styles.buttonMuted]: isMuted
  })

  let Container

  if (to) {
    Container = Link
  } else if (href) {
    Container = 'a'
  } else {
    Container = 'button'
  }

  return (
    <Container
      to={to}
      href={href}
      className={containerClassName}
      {...otherProps}
    >
      {children}
    </Container>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  isMuted: PropTypes.bool,
  to: PropTypes.string
}

export { Button }
