import * as React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './MenuToggle.module.css'

const Path = props => (
  <motion.path
    fill='transparent'
    strokeWidth='3.5'
    stroke='currentColor'
    strokeLinecap='round'
    {...props}
  />
)

const MenuToggle = ({ toggle, isOpen, isWhite }) => {
  const classNameIcon = classnames(styles.icon, {
    [styles.iconIsActive]: isOpen,
    [styles.iconIsWhite]: isWhite
  })

  const dataTest = `MenuToggle${isOpen ? 'Close' : 'Open'}`

  return (
    <button
      className={styles.toggle}
      onClick={toggle}
      aria-label='Menu'
      data-test={dataTest}
    >
      <svg
        viewBox='0 0 23 19'
        className={classNameIcon}
      >
        <Path
          variants={{
            closed: { d: 'M 2 2.5 L 20 2.5' },
            open: { d: 'M 3 16.5 L 17 2.5' }
          }}
        />
        <Path
          d='M 2 9.423 L 20 9.423'
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 }
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: 'M 2 16.346 L 20 16.346' },
            open: { d: 'M 3 2.5 L 17 16.346' }
          }}
        />
      </svg>
    </button>
  )
}

MenuToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isWhite: PropTypes.bool,
  toggle: PropTypes.func.isRequired
}

export { MenuToggle }
