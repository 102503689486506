import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import fontRegular from '../../fonts/Cera-Pro/Cera-Pro-Regular.woff2'
import fontMedium from '../../fonts/Cera-Pro/Cera-Pro-Medium.woff2'
import fontBlack from '../../fonts/Cera-Pro/Cera-Pro-Black.woff2'
import fontBold from '../../fonts/Cera-Pro/Cera-Pro-Bold.woff2'

function SEO({ description, lang, meta, title, author, ogImage }) {
  const { site, ogImageDefault } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        ogImageDefault: file(absolutePath: { regex: "./og-image/" }) {
          childImageSharp {
            fixed(height: 630, width: 1200) {
              src
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaAuthor = author || site.siteMetadata.author
  const metaOgImage = ogImage || ogImageDefault.childImageSharp.fixed.src

  // TODO filter actually used fields
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'og:image',
          content: metaOgImage,
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: metaAuthor
        },
        {
          name: 'author',
          content: metaAuthor
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'twitter:image',
          content: metaOgImage,
        }
      ].concat(meta)}
    >
      <link
        rel='preload'
        as='font'
        href={fontRegular}
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        as='font'
        href={fontMedium}
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        as='font'
        href={fontBlack}
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <link
        rel='preload'
        as='font'
        href={fontBold}
        type='font/woff2'
        crossOrigin='anonymous'
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  author: '',
  lang: 'pt-br',
  meta: [],
  description: '',
  ogImage: '',
  title: ''
}

SEO.propTypes = {
  author: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  ogImage: PropTypes.string,
  title: PropTypes.string.isRequired
}

export { SEO }
