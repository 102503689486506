import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import cn from 'classnames'

import { Image, Button } from '../UI'

import childrenToString from '../../helpers/childrenToString'

import styles from './VerticalAccordionContent.module.css'

function VerticalAccordionContent({ title, description, image, color, pack, link, isActive }) {
  const containerClassName = cn(styles.container, {
    [styles.containerBlue]: color === 'blue',
    [styles.containerBlack]: color === 'black',
    [styles.containerRed]: color === 'red',
    [styles.containerOrange]: color === 'orange',
    [styles.containerYellow]: color === 'yellow',
    [styles.containerGreen]: color === 'green'
  })
  const alt = childrenToString(title)

  return (
    <motion.div
      className={containerClassName}
      animate={{
        opacity: isActive ? 1 : 0,
        transition: {
          delay: isActive ? 0.6 : 0
        },
        transitionStart: {
          display: isActive ? 'none' : 'flex'
        },
        transitionEnd: {
          display: isActive ? 'flex' : 'none'
        }
      }}
    >
      <div className={styles.image}>
        <Image
          alt={alt}
          fluid={image}
        />
      </div>

      <div className={styles.titleWrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>

        {pack ? (
          <div className={styles.pack}>
            <div className={styles.packItem}>
              <Image
                className={styles.packLata}
                alt={pack.lata.alt}
                fluid={pack.lata.image}
              />
              <span className={styles.packDescription}>{pack.lata.description}</span>
            </div>
            <div className={styles.packItem}>
              <Image
                className={styles.packPet}
                alt={pack.pet.alt}
                fluid={pack.pet.image}
              />
              <span className={styles.packDescription}>{pack.pet.description}</span>
            </div>
          </div>
        ) : null}

        {link ? (
          <Button
            href={link}
            target='_blank'
            rel='noreferrer noopener'
            className={styles.button}
          >
            Comprar
          </Button>
        ) : null}
      </div>
    </motion.div>
  )
}

VerticalAccordionContent.propTypes = {
  color: PropTypes.oneOf(['black', 'blue', 'red', 'yellow', 'orange', 'green']),
  description: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
  image: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  link: PropTypes.string,
  pack: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired
}

export { VerticalAccordionContent }
