import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Image } from './'

import styles from './ImageAndText.module.css'

function ImageAndText({ image, title, renderTitle, subtitle, renderSubtitle, alt = '', children, classnames = {} }) {
  const containerClassName = cn('container', styles.container, classnames.container)
  const imageClassName = cn(styles.image, classnames.image)
  const imageWrapperClassName = cn(styles.imageWrapper, classnames.imageWrapper)
  const titleClassName = cn('title', styles.title, classnames.title)
  const subtitleClassName = cn('titleLarge', styles.subtitle, classnames.subtitle)
  const textClassName = cn(styles.text, classnames.text)
  const textWrapperClassName = cn(styles.textWrapper, classnames.textWrapper)

  return (
    <section className={containerClassName}>
      <figure className={imageWrapperClassName}>
        <Image
          alt={alt}
          className={imageClassName}
          fluid={image.childImageSharp.fluid}
        />
      </figure>
      <div className={textWrapperClassName}>
        <h1 className={titleClassName}>
          {title || renderTitle()}
          <strong className={subtitleClassName}>{subtitle || renderSubtitle()}</strong>
        </h1>

        <div className={textClassName}>{children}</div>
      </div>
    </section>
  )
}

ImageAndText.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classnames: PropTypes.shape({
    container: PropTypes.string,
    image: PropTypes.string,
    imageWrapper: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    textWrapper: PropTypes.string,
    title: PropTypes.string
  }),
  image: PropTypes.object.isRequired,
  renderSubtitle: PropTypes.func,
  renderTitle: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string
}

export { ImageAndText }
