import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { motion } from 'framer-motion'

import { Image } from '../UI'

import styles from './VerticalAccordionItem.module.css'

function VerticalAccordionItem({ accordionTitle, icon, color, children, isActive, onToggleClick }) {
  const toggleClassName = classnames(styles.toggle, {
    [styles.toggleBlue]: color === 'blue',
    [styles.toggleBlack]: color === 'black',
    [styles.toggleRed]: color === 'red',
    [styles.toggleOrange]: color === 'orange',
    [styles.toggleYellow]: color === 'yellow',
    [styles.toggleGreen]: color === 'green'
  })
  const flex = isActive ? 1 : 0
  const width = isActive ? 'auto' : 0

  return (
    <>
      <dt className={styles.titleWrapper}>
        <button
          className={toggleClassName}
          onClick={onToggleClick}
        >
          <div className={styles.iconWrapper}>
            <div className={styles.icon}>
              <Image
                alt={accordionTitle}
                fluid={icon}
              />
            </div>
          </div>
          <div className={styles.title}>{accordionTitle}</div>
          <motion.div
            className={styles.toggleisActive}
            animate={{
              visibility: isActive ? 'visible' : 'none',
              x: isActive ? '13px' : '0'
            }}
          />
        </button>
      </dt>

      <motion.dd
        aria-hidden={!isActive}
        initial={{ flex, width }}
        animate={{ flex, width }}
        className={styles.description}
        transition={{
          delay: 0.3
        }}
      >
        {children}
      </motion.dd>
    </>
  )
}

VerticalAccordionItem.propTypes = {
  accordionTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  color: PropTypes.oneOf(['black', 'blue', 'red', 'yellow', 'orange', 'green']),
  icon: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onToggleClick: PropTypes.func.isRequired
}

export { VerticalAccordionItem }
