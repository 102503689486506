import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styles from './Footer.module.css'

const Footer = () => {
  const { logo, facebookLogo, instagramLogo } = useStaticQuery(
    graphql`
      query FooterImages {
        logo: file(relativePath: { eq: "home/icons/logo-coca-cola-brasil.svg" }) {
          publicURL
        }
        facebookLogo: file(relativePath: { eq: "home/icons/facebook.svg" }) {
          publicURL
        }
        instagramLogo: file(relativePath: { eq: "home/icons/instagram.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <footer className={styles.footer}>
      <div className={styles.info}>
        <div className={styles.contact}>
          <p>Fale com a gente:</p>
          <p>Segunda a sexta, das 8h às 18h.</p>
          <p>0800 212121</p>
        </div>
        <img
          alt='Coca-Cola Brasil'
          className={styles.logo}
          src={logo.publicURL}
        />
        <div className={styles.socialMedia}>
          <p>Todos os direitos reservados</p>
          <div className={styles.groupMedia}>
            <a
              className={styles.media}
              href='https://www.facebook.com/%C3%81gua-Crystal-633720586818046/'
              rel='noopener noreferrer'
              target='_blank'
            >
              <img
                alt='Facebook'
                src={facebookLogo.publicURL}
              />
            </a>
            <a
              className={styles.media}
              href='https://www.instagram.com/agua.crystal/'
              rel='noopener noreferrer'
              target='_blank'
            >
              <img
                alt='Instagram'
                src={instagramLogo.publicURL}
              />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.links}>
        <div className={styles.linksContainer}>
          <a
            className={styles.link}
            href='http://www.coca-colacompany.com/careers/'
            rel='noopener noreferrer'
            target='_blank'
          >
            Oportunidades de emprego
          </a>
          <a
            className={styles.link}
            href='http://www.coca-colacompany.com/investors/'
            rel='noopener noreferrer'
            target='_blank'
          >
            Informações a investidores
          </a>
          <a
            className={styles.link}
            href='https://www.cocacolabrasil.com.br/fale-conosco'
            rel='noopener noreferrer'
            target='_blank'
          >
            Fale conosco
          </a>
        </div>
        <div className={styles.groupMediaMobile}>
          <a
            className={styles.media}
            href='https://www.facebook.com/%C3%81gua-Crystal-633720586818046/'
            rel='noopener noreferrer'
            target='_blank'
          >
            <img
              alt='Facebook'
              src={facebookLogo.publicURL}
            />
          </a>
          <a
            className={styles.media}
            href='https://www.instagram.com/agua.crystal/'
            rel='noopener noreferrer'
            target='_blank'
          >
            <img
              alt='Instagram'
              src={instagramLogo.publicURL}
            />
          </a>
        </div>
        <div className={styles.linksContainer}>
          <a
            className={styles.link}
            href='https://www.cocacola.com.br/pt/termos-de-uso/'
            rel='noopener noreferrer'
            target='_blank'
          >
            Termos e condições
          </a>
          <a
            className={styles.link}
            href='https://www.cocacola.com.br/pt/politica-de-privacidade/'
            rel='noopener noreferrer'
            target='_blank'
          >
            Política de privacidade
          </a>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
