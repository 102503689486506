import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion, useTransform } from 'framer-motion'
import { useMeasure } from 'react-use'

import { Image } from '../UI'

import { useTimeline } from '../../hooks'

import childrenToString from '../../helpers/childrenToString'

import styles from './TimelineSliderItem.module.css'

const variants = {
  visible: {
    opacity: 1,
    height: 'auto',
    display: 'block'
  },

  hidden: {
    opacity: 0,
    height: 0,
    transitionEnd: { display: 'none' }
  }
}

const visuallyHiddenVariants = {
  visible: {
    opacity: 1,
    visibility: 'visible'
  },

  hidden: {
    opacity: 0,
    transitionEnd: { visibility: 'hidden' }
  }
}

function TimelineSliderItem({ thumb, title, subtitle, text, year, index }) {
  const [mouseCoords, setMouseCoords] = useState({ clientX: 0, clientY: 0 })
  const { itemWidth, middleX, dragX, activeIndex, isContentCollapsed, setIsContentCollapsed, goToItem } = useTimeline()
  const [aspectRatioRef, { height }] = useMeasure()
  const { fluid, original } = thumb.childImageSharp
  const aspectRatio = `${(original.height * 100) / original.width}%`
  const alt = childrenToString(title)
  const itemMiddleX = middleX - (itemWidth * index)
  const itemStartX = itemMiddleX - itemWidth
  const itemEndX = itemMiddleX + itemWidth
  const opacity = useTransform(dragX, [itemStartX, itemMiddleX, itemEndX], [0, 1, 0])
  const isActiveIndex = activeIndex === index
  const isTextVisible = isContentCollapsed && isActiveIndex
  const isSiblingTextVisible = isContentCollapsed && !isActiveIndex
  const subtitleVariant = isTextVisible ? 'hidden' : 'visible'
  const textVariant = isTextVisible ? 'visible' : 'hidden'
  const yearVariant = isTextVisible ? 'visible' : 'hidden'
  const transitionSettings = { delay: isTextVisible ? 0.2 : 0 }

  function getX() {
    if (activeIndex === index) {
      return 0
    } else if (activeIndex < index) {
      return '30%'
    }

    return '-30%'
  }

  function onMouseDown(event) {
    const clientX = event?.clientX ?? event.touches[0].clientX
    const clientY = event?.clientY ?? event.touches[0].clientY

    setMouseCoords({ clientX, clientY })
  }

  function onMouseUp(event) {
    const clientX = event?.clientX ?? event.touches[0].clientX
    const clientY = event?.clientY ?? event.touches[0].clientY
    const xDiff = Math.abs(mouseCoords.clientX - clientX)
    const yDiff = Math.abs(mouseCoords.clientY - clientY)
    const isClickLikeEvent = xDiff < 10 && yDiff < 10
    const isNextItem = index === activeIndex + 1

    if (isClickLikeEvent && isNextItem) {
      goToItem(index)
    }

    setMouseCoords({ clientX: 0, clientY: 0 })
  }

  return (
    <motion.li
      className={styles.container}
      style={{ width: itemWidth }}
      animate={{
        opacity: isSiblingTextVisible ? 0 : 1,
        x: isSiblingTextVisible ? getX() : 0
      }}
    >
      <div
        className={styles.wrapper}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchEnd={onMouseUp}
      >
        <Image
          alt={alt}
          fluid={fluid}
          className={styles.image}
        />

        <motion.div
          style={{ opacity }}
          className={styles.content}
        >
          <div
            ref={aspectRatioRef}
            className={styles.imageAspectRatio}
          >
            <div style={{ paddingBottom: aspectRatio }} />
          </div>

          <motion.div
            className={styles.text}
            animate={{ y: isTextVisible ? 10 : height }}
            transition={transitionSettings}
          >
            <h2 className={styles.title}>
              <motion.small
                className={styles.year}
                animate={yearVariant}
                variants={visuallyHiddenVariants}
                transition={transitionSettings}
              >
                {year}
              </motion.small>

              {title}
            </h2>

            <motion.p
              variants={variants}
              className={styles.subtitle}
              initial={subtitleVariant}
              animate={subtitleVariant}
              transition={transitionSettings}
            >
              {subtitle}
            </motion.p>

            <motion.p
              variants={variants}
              className={styles.subtitle}
              initial={textVariant}
              animate={textVariant}
              transition={transitionSettings}
            >
              {text}
            </motion.p>

            <button
              className={styles.button}
              aria-label='Mais detalhes'
              title='Mais detalhes'
              onClick={() => setIsContentCollapsed(!isContentCollapsed)}
              tabIndex={isActiveIndex ? 0 : -1}
            >
              {isTextVisible ? '-' : '+'}
            </button>
          </motion.div>
        </motion.div>
      </div>
    </motion.li>
  )
}

TimelineSliderItem.propTypes = {
  index: PropTypes.number.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  text: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  thumb: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  year: PropTypes.string.isRequired
}

export { TimelineSliderItem }
