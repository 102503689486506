import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import Carousel from 'react-slick'
import { motion } from 'framer-motion'

import { Image } from '../UI'

import { TimelineYearSelect } from '.'

import { useTimeline } from '../../hooks'

import childrenToString from '../../helpers/childrenToString'

import styles from './TimelineMobile.module.css'

function TimelineMobile() {
  const { content, contentYears, thumbs } = useTimeline()
  const years = Object.keys(contentYears)
  const [activeYear, setActiveYear] = useState(years[0])
  const [currentSlide, setCurrentSlide] = useState(0)
  const titleClassName = classnames('titleLarge', styles.title)
  const containerClassName = classnames('container', styles.container)
  const carouselRef = useRef(null)

  function renderItems() {
    return content.map(item => {
      const thumb = thumbs.allFile.edges.find(t => t.node.name === item.thumb).node
      const alt = childrenToString(item.title)

      return (
        <div
          key={item.thumb}
          className={styles.item}
        >
          <Image
            alt={alt}
            className={styles.image}
            fluid={thumb.childImageSharp.fluid}
          />

          <h3 className={styles.itemTitle}>{item.title}</h3>

          <div className={styles.text}>{item.text}</div>
        </div>
      )
    })
  }

  function renderDots() {
    return (
      <motion.ul
        className={styles.dots}
        initial={{ opacity: 0 }}
        animate={{ opacity: contentYears[activeYear].length > 1 ? 1 : 0 }}
      >
        {contentYears[activeYear].map(item => {
          const title = `Ir para slide "${childrenToString(item.title)}"`
          const slideIndex = content.findIndex(currentItem => currentItem.thumb === item.thumb)
          const dotClassName = classnames(styles.dot, {
            [styles.dotIsActive]: currentSlide === slideIndex
          })

          return (
            <li key={item.thumb}>
              <button
                title={title}
                aria-label={title}
                className={dotClassName}
                onClick={() => carouselRef.current.slickGoTo(slideIndex)}
              />
            </li>
          )
        })}
      </motion.ul>
    )
  }

  return (
    <div className={containerClassName}>
      <h2 className={titleClassName}>o que estamos fazendo</h2>

      <TimelineYearSelect
        activeYear={activeYear}
        onYearSelect={year => {
          const slideIndex = content.findIndex(currentItem => currentItem.year === year)
          setActiveYear(year)
          carouselRef.current.slickGoTo(slideIndex)
        }}
      />

      <Carousel
        centerMode
        adaptiveHeight
        variableWidth
        arrows={false}
        className={styles.carousel}
        ref={carouselRef}
        beforeChange={(currSlide, nextSlide) => {
          const newYear = content[nextSlide].year
          setActiveYear(newYear)
          setCurrentSlide(nextSlide)
        }}
      >
        {renderItems()}
      </Carousel>

      {renderDots()}
    </div>
  )
}

export { TimelineMobile }
